





















import { Component, Vue } from 'vue-property-decorator';

@Component({
	data: function() {
		return {};
	},
	props: {
		images: Array,
		interval: Number,
		over: Boolean
	},
	computed: {
		carouselHeight() {
			return window.innerWidth < 600 ? 50 : 140;
		}
	}
})
export default class BannerImages extends Vue {}
