


























import { Component, Vue } from 'vue-property-decorator';

@Component({
	data: function() {
		return {};
	},
	props: {
		title: String
	},
	methods: {},
	computed: {}
})
export default class SectionBackground extends Vue {}
