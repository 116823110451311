


























import { Component, Vue } from 'vue-property-decorator';

@Component({
	props: {
		info: {
			type: Object,
			required: true
		}
	}
})
export default class EmploymentCard extends Vue {}
