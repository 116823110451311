















































































































import { Component, Vue } from 'vue-property-decorator';
import SectionBackground from '@/components/SectionBackground.vue';
import EducationCard from '@/components/EducationCard.vue';
import EmploymentCard from '@/components/EmploymentCard.vue';
import Footer from '@/components/Footer.vue'; // @ is an alias to /src
import BannerImages from '@/components/BannerImages.vue'; // @ is an alias to /src

import imageOver from '@/mixins/imageOver';
import mobileUtils from '@/mixins/mobileUtils';

BannerImages;

@Component({
	components: {
		EducationCard,
		EmploymentCard,
		SectionBackground,
		BannerImages
	},
	mixins: [imageOver, mobileUtils],
	data() {
		return {
			images: [
				{
					src: require('../assets/back/back5.webp')
				},
				{
					src: require('../assets/back/back1.webp')
				},
				{
					src: require('../assets/back/back2.webp')
				}
			]
		};
	},
	computed: {
		educationInfo() {
			return this.$store.getters.educationInfo;
		},
		employmentInfo() {
			return this.$store.getters.employmentInfo;
		},
		skills() {
			return this.$store.getters.skills;
		}
	},
	created() {
		this.$store.commit('changeCurrentTab', this.$route.name);
	}
})
export default class Home extends Vue {}
